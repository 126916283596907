$bootstrap-sass-asset-helper: false !default;
$browser-context: 16; // Default
@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

$container-max-widths: (
  sm: 1330px,
);

$body-bg: #f2f2f2;
$primary: #008559;
$secondary: #6d6e70;
$gray-100: #fbfbfb;
$gray-200: #dedede;
$gray-300: #a4a4a4;
$gray-400: #959595;
$gray-500: #868686;
$gray-600: #727272;
$gray-700: #565656;
$gray-900: #2C2E35;
$border-color: #b7b7b7;
$text-muted: $gray-400;
$white: #fff;

$font-family-sans-serif: 'Inter', 'Arial', 'Helvetica Neue', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-arabic: 'Inter', 'Arial', 'Helvetica Neue', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-base: 'Inter', 'Arial', 'Helvetica Neue', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-size-base: 1rem;
$font-size-lg: 1.0625rem;
$font-size-sm: 0.9375rem;

$headings-color: #313131;

$rounded-lg: 8px;

$h4-font-size: rem(24);

$btn-primary: #0DA06E;