@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';
@import 'settings';
@import 'font-face';
@import 'react-redux-toastr/src/styles/index';

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.text-gray-700 {
  color: $gray-700;
}

.redux-toastr {
  .top-center {
    width: auto;
    margin-left: 0;
    transform: translateX(-50%);
    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 650px;
    }
  }
  .toastr {
    opacity: 1;
    min-height: auto;
    padding: 10px 15px;
    color: $gray-900;
    &.rrt-error {
      background-color: #fff7f4;
      .rrt-left-container .toastr-icon {
        fill: #ff7043;
      }
    }
    &.rrt-success {
      background-color: #edf6f6;
      .rrt-left-container .toastr-icon {
        fill: $primary;
      }
    }
    & > div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .rrt-left-container,
    .rrt-right-container {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -moz-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      display: inline-flex;
      align-items: center;
    }
    .rrt-left-container {
      .rrt-holder {
        position: relative;
        width: auto;
        height: auto;
        margin: 0;
        top: 0;
        left: 0;
        display: inline-flex;
        align-items: center;
      }
      .toastr-icon {
        margin: 0;
        width: rem(21) !important;
        height: rem(21) !important;
      }
    }
    .rrt-middle-container {
      -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -moz-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
      width: 100%;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      margin: 0;
      display: flex;
      font-weight: 600;
      .rrt-title {
        margin: 0;
        font-size: 15px;
        margin: 0 5px;
        font-weight: 500;
        color: $gray-900;
        display: none;
      }
      .rrt-text {
        font-size: 15px;
        color: $gray-900;
      }
    }
    .close-toastr {
      position: relative;
      opacity: 1;
      color: $gray-900;
      width: auto;
      font-size: 18px;
      padding: 0;
    }
  }
}

.itemTooltip {
  position: relative;

  .tooltiptext {
    visibility: hidden;
    width: 175px;
    background-color: #fff;
    color: $gray-900;
    text-align: left;
    border-radius: 6px;
    padding: 8px 10px;
    position: absolute;
    z-index: 9;
    top: -9px;
    text-transform: none;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
    border: 1px solid #cbcbcb;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    transform: translateY(-100%);

    &::after {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 20px;
      width: 12px;
      height: 12px;
      background-color: $white;
      border-width: 1px;
      border-style: solid;
      border-color: transparent #cbcbcb #cbcbcb transparent;
      transform: rotate(45deg);
    }
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}
